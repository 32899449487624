// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#contact-form {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#contact-form h1,
#contact-form h1 span {
  color: #464646;
  font-family: 'Cinzel', serif;
  color: #464646;
  font-family: 'Cinzel', serif;
  font-size: 26px;
}

#contact-form h1 span {
  font-size: 30px;
}

#contact-form .form-input {
  width: 100%;
  height: 20px;
}

#contact-form #default-btn {
  width: 100%;
}

#contact-form input{
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 8px;
}
#contact-form textarea{
  width: 100%;
  padding: 8px;
}

@media (max-width: 400px) {
  #contact-form {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./client/styles/ContactModal.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;;EAEE,cAAc;EACd,4BAA4B;EAC5B,cAAc;EACd,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["#contact-form {\n  width: 75%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n#contact-form h1,\n#contact-form h1 span {\n  color: #464646;\n  font-family: 'Cinzel', serif;\n  color: #464646;\n  font-family: 'Cinzel', serif;\n  font-size: 26px;\n}\n\n#contact-form h1 span {\n  font-size: 30px;\n}\n\n#contact-form .form-input {\n  width: 100%;\n  height: 20px;\n}\n\n#contact-form #default-btn {\n  width: 100%;\n}\n\n#contact-form input{\n  width: 100%;\n  height: 40px;\n  margin-bottom: 10px;\n  padding: 8px;\n}\n#contact-form textarea{\n  width: 100%;\n  padding: 8px;\n}\n\n@media (max-width: 400px) {\n  #contact-form {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
