import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';

import '../styles/Team.css';

const members = [
  {
    id: 1,
    img: './assets/images/bradley.jpg',
    name: 'Bradley Max, Esq.',
    title: 'Director',
    phone: '(212) 259-4318',
    email: 'bmax@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/bradley-max-0750887/',
    detail: `Bradley has thirty years of experience in corporate credit markets and bankruptcy litigation strategy. 
            He has originated, sourced, sold and traded a variety of private debt instruments, including: bankruptcy claims, 
            litigation finance, private notes, LP interests, insurance liquidation/subrogation claims, account receivable puts and 
            physical energy assets. Previously, Bradley held similar roles at two large hedge funds: Bowery Investment Management 
            LLC and Longacre Fund Management LLC. Prior to this, Bradley was a bankruptcy litigator at major law firms. Bradley earned 
            his J.D. from Quinnipiac University School of Law and his B.A. from Rollins College.`
  },
  {
    id: 2,
    img: './assets/images/brian.jpg',
    name: 'Brian Ferrara',
    title: 'Sourcing Specialist',
    phone: '(212) 259-4333',
    email: 'bferrara@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/brianferrara1/',
    detail: `Brian Ferrara is a Sourcing Specialist at Cherokee. Previously, he was a registered Representative at an 
            asset management firm where he provided investment advice to individuals and institutions. Brian received 
            his undergraduate degree from Pace University and his M.B.A. from Baruch College's Zicklin School of Business 
            with a dual concentration in finance and economics.`
  },
  {
    id: 3,
    img: './assets/images/jim.jpg',
    name: 'James Larkin',
    title: 'Chief Operating Officer',
    email: 'jlarkin@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/jim-larkin-0a9a0b12/',
    detail: `James Larkin is the Chief Operating Officer of Cherokee Debt Acquisition, LLC.  From 2001 to 2017, 
            James worked in two hedge funds, Longacre Fund Management, LLC and Bowery Investment Management, LLC, 
            where he assumed a variety of roles including Chief Operating Officer and Chief Compliance Officer.  
            James is a New York State licensed Certified Public Accountant and earned his Bachelor of Science in 
            Business Administration degree from Colorado State University.`
  },
  {
    id: 5,
    img: './assets/images/jean-luc-ramdin.png',
    name: 'Jean-Luc Ramdin',
    title: 'Sales & Trading Analyst',
    email: 'marc@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/jean-luc-ramdin-65754589/',
    detail: `Jean-Luc Ramdin is a Sales & Training Analyst at Cherokee.  HE has international experience supporting data analytic projects as well as integrating with initiatives in the Web3 and decentralized finance sphere. Jean-Luc was recently granted and undergraduate degree in finance as a highly decorated student-athlete , at the University of Houston-Victoria.`
  },
  {
    id: 4,
    img: './assets/images/lisa.jpg',
    name: 'Lisa Faucher',
    title: 'Manager',
    email: 'lfaucher@cherokeeacq.com',
    detail: `Lisa Faucher is the Manager of Trade Claims and Office Administration of Cherokee Debt Acquisition, LLC.  
            From 2012 to 2017, Lisa worked for Bowery Investment Management, LLC and previously worked at Longacre Fund Management, 
            LLC, where she was also responsible for trade claims and office administration.  Prior to Longacre, she was an Executive 
            Assistant at Integrated Risk Services.  From 2002 to 2006, Lisa was a Special Services Associate and a Computer Room 
            Associate at Home Depot.  Lisa graduated from Molloy College with a B.A. degree in English and Childhood Education.`
  },
  
  {
    id: 6,
    img: './assets/images/vlad-2.jpg',
    name: 'Vladimir Jelisavcic',
    title: 'Manager',
    email: 'vjel@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/vladimir-jelisavcic-17222688/',
    detail: `Founder of Cherokee Acquisition, 2017; Founder of Bowery Opportunity Funds, 2009; Co-founder of Longacre Fund Management, 
            LLC, 1999. From 1993 to 1998, he was a Vice President in the High Yield Department of Bear, Stearns & Co. Inc., where he was 
            responsible for trading distressed bank loans and trade claims. From 1987 to 1990, Mr. Jelisavcic worked in the Tax Department 
            of Deloitte & Touche. Mr. Jelisavcic was a Certified Public Accountant and earned a J.D. from the University of Iowa College of 
            Law in 1993 and a B.S. in Accounting from New York University in 1987. `
  }
];

console.log(members);

const memberCard = member => (
  <div className="member-container" key={member.id}>
    <div className="image-container">
      <img src={member.img} />
    </div>
    <h3>{member.name}</h3>
    <h4>{member.title}</h4>
    {member.phone && <h4>{member.phone}</h4>}
    <div className="link-container">
      {member.linkedin && (
        <a href={member.linkedin} target="_blank">
          <img className="linkedin" src="./assets/icons/linkedin.png" />
        </a>
      )}
      <a href={`mailto:${member.email}`}>
        <img className="email" src="./assets/icons/email.png" />
      </a>
    </div>
    <div className="detail-container">
      <p>{member.detail}</p>
    </div>
  </div>
);

const Team = () => {
  return (
    <div id="team-container">
      <Helmet>
        <title>Team</title>
        <meta
          name="description"
          content="Meet the team behind Cherokee Acquisition."
        />
      </Helmet>
      <Header isHome={false} />
      <div id="team">
        <div className="intro">
          <h1 className="title">Team</h1>
          <div className="info-container">
            {members.map(member => memberCard(member))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
