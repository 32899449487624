// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#default-btn {
  background-color: #000078;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

#default-btn:hover {
  background-color: #0000a8;
}
`, "",{"version":3,"sources":["webpack://./client/styles/Button.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["#default-btn {\n  background-color: #000078;\n  color: #ffffff;\n  text-align: center;\n  padding: 10px;\n  width: 240px;\n  margin-left: auto;\n  margin-right: auto;\n  cursor: pointer;\n}\n\n#default-btn:hover {\n  background-color: #0000a8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
