import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';

import '../styles/Resources.css';


const groups = [ 
  	{ 
		group: 'Wired',
		logo: './assets/images/wired.png', 
		resources: [
			{
				id: 1,
				name: 'Wired: Crypto Bankruptcy Markets Are Thriving After FTXs Collapse',
				month: '3',
				day: '30',
				year: '2023',
				logo: './assets/images/wired.png',
				url: 'https://www-wired-com.cdn.ampproject.org/c/s/www.wired.com/story/crypto-bankruptcy-markets-thriving-ftx-collapse/amp'
			},
		]
  	},
  	{
    	group: 'Bloomberg',
    	logo: './assets/images/bloomberg.png',
    	resources: [
			{
				id: 2,
				name: 'Bloomberg: Wall Street Pros Offer Crypto Holders a Backdoor Bankruptcy Exit',
				month: '8',
				day: '9',
				year: '2022',
				logo: './assets/images/bloomberg.png',
				url: 'https://www.cherokeeacq.com/assets/media/bloomberg-article-jeremy-hill.pdf'
			},
		]
  	},
  	{
		group: 'Medium',
		logo: './assets/images/medium-logo.png',
    	resources: [
      		{
				id: 3,
				name: "Cherokee's Vladimir Jelisavcic Introduces Claims Market",
				month: '8',
				day: '17',
				year: '2020',
				logo: './assets/images/medium.png',
				url: 'https://medium.com/@jellyroll505/bankruptcy-claims-a4805bad307d'
			},
		]
  	},
  	{
    	group: 'Claims Market',
    	logo: './assets/images/claims-market-logo-blue.png',
		resources: [
			{
				id: 4,
				name: "Cherokee's Vladimir Jelisavcic on FTX & Celsius Bankruptcy Claims (Twitter Spaces)",
				month: '11',
				day: '27',
				year: '2022',
				logo: './assets/images/claims-market-logo-blue.png',
				url: 'https://www.youtube.com/watch?app=desktop&v=b1bS3ajkFRc',
			},
			{
				id: 5,
				name: "Claims Market's Bradley Max on Trading Bankruptcy Claims",
				month: '3',
				day: '17',
				year: '2022',
				logo: './assets/images/claims-market-logo-blue.png',
				url: "https://www.youtube.com/watch?v=GRbvpegLgQg"
			}
		]
  	}
];

console.log(groups);
const groupCard = group => (
  <div className="member-container" key={group.group}>
		<div className="col-3">
			<img src={group.logo} alt={group.group} className="img-responsive" />
		</div> 
		<div className='col-9'>
			{group.resources.map(resource => resourceCard(resource))}
		</div>
  </div>
);

const resourceCard = (resource) => (
  <div className="offsiteLink" key={resource.id}>
    <a href={resource.url} className="link" target="_blank">
        {resource.name} ({resource.month}.{resource.day}.{resource.year})
      </a>      
  </div>
);

const Media = () => {
  return (
    <div id="resources-container">
      <Helmet>
        <title>Resources</title>
        <meta
          name="description"
          content="Check out some of the resources behind Cherokee Acquisition."
        />
      </Helmet>
      <Header isHome={false} />
      <div id="resources">
        <div className="intro">
          <h1 className="title">Resources</h1>
          <img className="team-icon" src="./assets/icons/internships.png" />
          <div className="info-container">
            {groups.map(group => groupCard(group))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
