import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import ContactModal from './ContactModal';
import * as Scroll from 'react-scroll';

const animateScroll = Scroll.animateScroll;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.year = new Date().getFullYear()


    this.state = {
      menuActive: false,
      contactActive: false
    };
  }

  openContact = () => {
    animateScroll.scrollToTop();
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <div id="footer">
        <div className="info-container">
          <div className="brand-info">
            <Link to="/" className="link" onClick={animateScroll.scrollToTop}>
              <h4>Cherokee Acquisition</h4>
            </Link>

            <a
              className="address"
              href="https://goo.gl/maps/iXMt5k1c7xLJfNA67"
              target="_blank"
              rel="noopener noreferrer">

              <div>1384 Broadway, Suite 906</div>
              <div>New York, NY 10018</div>
            </a>

            <p><a href="tel:2122594300">(212) 259-4300</a></p>

            <p><a href="mailto:all@cherokeeacq.com">all@cherokeeacq.com</a></p>
          </div>

          <div className="link-container">
            <Link
              to="/claims"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Claims
            </Link>

            <Link
              to="/put-options"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Vendor Put Options
            </Link>

            <Link
              to="/receivables"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Receivables Finance
            </Link>

            <Link
              to="/team"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Team
            </Link>

            <Link
              to="/resources"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Resources
            </Link>
          </div>
        </div>

        <div className="copy-right">
          <div className="linkedin">
              <a href="https://www.linkedin.com/company/cherokee-acquisition/">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="white" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
              </a>
          </div>
          <p>Copyright © {this.year} Cherokee Trading, LLC.</p>
          <a href="https://ravennainteractive.com" target="_blank">
            Powered by Ravenna
          </a>
        </div>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />
          
      </div>
    );
  }
}

export default Footer;
