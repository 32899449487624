import React from 'react';
import '../styles/Carousel.css';

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: 0,
      second: 1,
      third: 2
    };
  }

  prevPress = () => {
    this.setState(prevState => {
      return {
        first: prevState.first + 1 > 2 ? 0 : prevState.first + 1,
        second: prevState.second + 1 > 2 ? 0 : prevState.second + 1,
        third: prevState.third + 1 > 2 ? 0 : prevState.third + 1
      };
    });
  };

  nextPress = () => {
    this.setState(prevState => {
      return {
        first: prevState.first - 1 < 0 ? 2 : prevState.first - 1,
        second: prevState.second - 1 < 0 ? 2 : prevState.second - 1,
        third: prevState.third - 1 < 0 ? 2 : prevState.third - 1
      };
    });
  };

  render() {
    const { items } = this.props;
    const { first, second, third } = this.state;
    const imgOne = items[first];
    const imgTwo = items[second];
    const imgThree = items[third];
    return (
      <div className="carousel">
        <div className="carousel-container">
          <div className="carousel-img-container">
            <div className="carousel-image-cover" />
            <img src={imgOne} className="carousel-image" />
          </div>
          <div className="carousel-focus-container">
            <img src={imgTwo} className="carousel-focus-image" />
          </div>
          <div className="carousel-img-container">
            <div className="carousel-image-cover" />
            <img src={imgThree} className="carousel-image" />
          </div>
        </div>
        <div className="arrow-container">
          <img src="./assets/icons/arrow_left.svg" onClick={this.prevPress} />
          <img src="./assets/icons/arrow_right.svg" onClick={this.nextPress} />
        </div>
      </div>
    );
  }
}
