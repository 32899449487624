import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Button from './Button';
import ContactModal from './ContactModal';

import '../styles/Receivables.css';

class Receivables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactActive: false
    };
  }

  openContact = () => {
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <div id="venture-container">
        <Helmet>
          <title>Receivables Finance</title>
          <meta
            name="description"
            content="Cherokee provides efficient access to capital for vendors. We are strategically positioned between vendor clients and providers of capital who can service invoices. We follow over 100 credits and are able to offer insights on multiple industries." />

        </Helmet>

        <Header isHome={false} />

        <div id="venture">
          <div className="intro">
            <h1 className="title">Receivables Finance</h1>

            <div className="receivable-contact">
              <Button title="Contact Us" onButtonClick={this.openContact} />
            </div>
            <div className="detail-container">
              <div className="img-container first">
              <img
                  src="./assets/images/cherokee-coworkers.png"
                  alt="cherokee acquisition co-workers coworkers" />
            
              </div>

              <div className="description-container">
                <p>
                  Cherokee provides efficient access to capital for vendors. 
                  We are strategically positioned between vendor clients and 
                  providers of capital who can service invoices. We follow 
                  over 100 credits and are able to offer insights on multiple 
                  industries.
                </p>
              </div>
            </div>

            <div className="detail-container">
              <div className="description-container second">
                <p>
                  The Cherokee team has executed numerous financings for vendors 
                  since 1999 on multiple customers including: Bed Bath &amp; Beyond, PG&E, SoCalEd, Target, TJ Maxx and Walmart.
                </p>
              </div>

              <div className="img-container second">
                <img src="./assets/images/venture_intro_3.png" />
              </div>
            </div>

          </div>

          {/* <div className="portfolio">
            <h1 className="title">Portfolio</h1>
            <img src="./assets/icons/panda_analytic_logo.png" />
            <p>
              A passive investment tool that allows crypto investors to build a
              diversified portfolio and execute them in a user-friendly manner.
            </p>

            <a
              href="http://www.pandaanalytics.com"
              target="_blank"
              rel="noopener noreferrer">www.pandaanalytics.com</a>

            <img
              className="elementus-icon"
              src="./assets/icons/elementus_logo.png" />

            <p>
              Elementus is the Universal Blockchain Query Engine. We make
              blockchain data easy to decipher, allowing financial institutions,
              regulators, and exchanges to monitor and investigate
              cryptocurrency flows in real time.
            </p>

            <a
              href="https://elementus.io/"
              target="_blank"
              rel="noopener noreferrer">elementus.io</a>

            <img className="ic-icon" src="./assets/icons/ic_logo.png" />
            <p>
              Iterative Capital is an industry-leading investment management
              platform providing financial services to emergent digital
              economies.
            </p>

            <a
              href="https://iterative.capital/"
              target="_blank"
              rel="noopener noreferrer">iterative.capital</a>

            <img
              className="ledgerx-icon"
              src="./assets/icons/ledgerx_logo.png" />

            <p>
              LedgerX is a trading and clearing platform that received the first
              regulatory approval from the U.S. Commodity Futures Trading
              Commission (CFTC) to trade and clear derivatives on Bitcoin and
              other digital currencies. LedgerX holds two licenses with the
              CFTC; one as a swap execution facility (SEF) and second as a
              derivatives clearing organization (DCO).
            </p>

            <a
              href="https://ledgerx.com/"
              target="_blank"
              rel="noopener noreferrer">ledgerx.com</a>

            <img className="bits-icon" src="./assets/icons/bits-icon.png" />
            <p>
              Bits is a brand loyalty and investing platform creating a new
              currency for rewards programs - Bits of Stock. Get Bits of Stock
              when you spend with the brands you love. Spend with Amazon, get
              stocks in Amazon.
            </p>

            <a
              href="https://getbits.nl/"
              target="_blank"
              rel="noopener noreferrer">getbits.nl</a>

            <img className="petro-icon" src="./assets/icons/petro-icon.png" />
            <p>
              PetroValues.com is a highly-differentiated Oil & Gas Marketplace.
              The company provides Free Listings of Mineral Assets, Appraisals,
              and Free Well & Production Data. PetroValues has digitized the
              valuation and Purchase-Sale process of oil & gas properties. This
              enables to offer free advisory and brokerage services to any
              Mineral Owner.
            </p>

            <p>
              PetroValues’ huge competitive advantage is built on proprietary
              algorithms and the automation of valuation data tasks, while
              maintaining a personal and interpretive approach to geology and
              oil & gas production forecasting.
            </p>

            <a
              href="https://petrovalues.com/"
              target="_blank"
              rel="noopener noreferrer">PetroValues.com</a>

          </div> */}
          {/* <div className="apply">
            <Button
              title="Apply"
              onButtonClick={() =>
                (window.location.href = `mailto:all@cherokeeacq.com`)
              } />

          </div> */}
        </div>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />

      </div>
    );
  }
}

export default Receivables;
