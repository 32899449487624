import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client'

import { Router } from 'react-router-dom';
import history from './history';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <Router history={history}>
      <App />
  </Router>
);