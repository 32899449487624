// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews-wrapper{
    width: 100%;
    height: 800px;
    padding-bottom: 20px;
}
.reviews-wrapper iframe{
    width:100%;
    height: 100%;
    border: 0px;
}`, "",{"version":3,"sources":["webpack://./client/styles/Reviews.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,oBAAoB;AACxB;AACA;IACI,UAAU;IACV,YAAY;IACZ,WAAW;AACf","sourcesContent":[".reviews-wrapper{\n    width: 100%;\n    height: 800px;\n    padding-bottom: 20px;\n}\n.reviews-wrapper iframe{\n    width:100%;\n    height: 100%;\n    border: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
