import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';

import '../styles/Team.css';

const members = [
  {
    id: 1,
    img: './assets/images/bradley.jpg',
    name: 'Bradley Max, Esq.',
    title: 'Director',
    phone: '(212) 259-4318',
    email: 'bmax@cherokeeacq.com',
    telegram: 'https://t.me/brad_max',
    linkedin: 'https://www.linkedin.com/in/bradley-max-0750887/',
    detail: ` Bradley has thirty years of experience in corporate credit markets and bankruptcy litigation strategy. He has originated, sourced, sold and traded a variety of private debt instruments including bankruptcy claims, litigation finance, private notes, LP interests, insurance liquidation/subrogation claims and puts on accounts receivable. Bradley’s legal background and focus on special situations investment opportunities has resulted in a portfolio of mid-continent physical energy assets and the development of an oil and gas asset trading business. Previously, Bradley held similar roles at two large hedge funds: Bowery Opportunity Funds and Longacre Funds. Prior to this, Bradley was a bankruptcy litigator at major law firms. Bradley earned his J.D. from Quinnipiac University School of Law and his B.A. from Rollins College.`

  },
  {
    id: 2,
    img: './assets/images/brian.jpg',
    name: 'Brian Ferrara',
    title: 'Sourcing Specialist',
    phone: '(212) 259-4333',
    email: 'bferrara@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/brianferrara1/',
    telegram: 'https://t.me/FTX_Claim_Buyer',
    detail: `Brian Ferrara is a Sourcing Specialist at Cherokee. Previously, he was a registered Representative at an 
            asset management firm where he provided investment advice to individuals and institutions. Brian received 
            his undergraduate degree from Pace University and his M.B.A. from Baruch College's Zicklin School of Business 
            with a dual concentration in finance and economics.`
  },
  {
    id: 3,
    img: './assets/images/jim.jpg',
    name: 'James Larkin',
    title: 'Chief Operating Officer',
    email: 'jlarkin@cherokeeacq.com',

    linkedin: 'https://www.linkedin.com/in/jim-larkin-0a9a0b12/',
    detail: `James Larkin is the Chief Operating Officer of Cherokee Debt Acquisition, LLC.  From 2001 to 2017, 
            James worked in two hedge funds, Longacre Fund Management, LLC and Bowery Investment Management, LLC, 
            where he assumed a variety of roles including Chief Operating Officer and Chief Compliance Officer.  
            James is a New York State licensed Certified Public Accountant and earned his Bachelor of Science in 
            Business Administration degree from Colorado State University.`
  },
  {
    id: 5,
    img: './assets/images/jean-luc-ramdin.png',
    name: 'Jean-Luc Ramdin',
    title: 'Sales & Trading Analyst',
    email: 'jlramdin@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/jean-luc-ramdin-65754589/',
    telegram: 'https://t.me/Jeanatthescene',
    detail: ` Jean-Luc Ramdin is a Sales & Trading Analyst at Cherokee. He has international experience supporting data analytics projects as well as integrating with initiatives in the Web3 and decentralized finance sphere. An outstanding leader and student athlete, Jean-Luc achieved his undergraduate degree in finance at the University of Houston-Victoria in 2022.`
  },
  {
    id: 4,
    img: './assets/images/lisa.jpg',
    name: 'Lisa Faucher',
    title: 'Manager',
    email: 'lfaucher@cherokeeacq.com',
    detail: `Lisa Faucher is the Manager of Trade Claims and Office Administration of Cherokee Debt Acquisition, LLC.  
            From 2012 to 2017, Lisa worked for Bowery Investment Management, LLC and previously worked at Longacre Fund Management, 
            LLC, where she was also responsible for trade claims and office administration.  Prior to Longacre, she was an Executive 
            Assistant at Integrated Risk Services.  From 2002 to 2006, Lisa was a Special Services Associate and a Computer Room 
            Associate at Home Depot.  Lisa graduated from Molloy College with a B.A. degree in English and Childhood Education.`
  },
  
  {
    id: 6,
    img: './assets/images/vlad-2.jpg',
    name: 'Vladimir Jelisavcic',
    title: 'Manager',
    email: 'vjel@cherokeeacq.com',
    linkedin: 'https://www.linkedin.com/in/vladimir-jelisavcic-17222688/',
    detail: `Founder of Cherokee Acquisition, 2017; Founder of Bowery Opportunity Funds, 2009; Co-founder of Longacre Funds, 1999. From 1993 to 1998, Vlad was a Vice President in the High Yield Department of Bear, Stearns & Co. responsible for trading distressed bank loans and private notes as well as the identification and analysis of investment opportunities in distressed securities. In 1991, Vlad worked as a law clerk for the S.E.C. From 1987 to 1990, Vlad worked in the Tax Department of Deloitte, was a C.P.A. in the State of New York and published articles on trading claims and creditors’ rights in the Journal of Corporation Law. Vlad earned a J.D. from the University of Iowa in 1993 and a B.S. in Accounting from NYU in 1987.`
  }
];

const memberCard = member => (
  <div className="member-container" key={member.id}>
    <div className="image-container">
      <img src={member.img} />
    </div>
    <h3>{member.name}</h3>
    <h4>{member.title}</h4>
    {member.phone && <h4>{member.phone}</h4>}
    <div className="link-container">
      {member.linkedin && (
        <a href={member.linkedin} target="_blank">
          <img className="linkedin" src="./assets/icons/linkedin.png" />
        </a>
      )}
      <a href={`mailto:${member.email}`}>
        <img className="email" src="./assets/icons/email.png" />
      </a>
      {member.telegram && (
        <a href={member.telegram} className="telegram" target="_blank">
             <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path fill="#666" d="M512 256C512 114.62 397.38 0 256 0S0 114.62 0 256s114.62 256 256 256 256-114.62 256-256zm-396.12-2.7c74.63-32.52 124.39-53.95 149.29-64.31 71.1-29.57 85.87-34.71 95.5-34.88 2.12-.03 6.85.49 9.92 2.98 2.59 2.1 3.3 4.94 3.64 6.93.34 2 .77 6.53.43 10.08-3.85 40.48-20.52 138.71-29 184.05-3.59 19.19-10.66 25.62-17.5 26.25-14.86 1.37-26.15-9.83-40.55-19.27-22.53-14.76-35.26-23.96-57.13-38.37-25.28-16.66-8.89-25.81 5.51-40.77 3.77-3.92 69.27-63.5 70.54-68.9.16-.68.31-3.2-1.19-4.53s-3.71-.87-5.3-.51c-2.26.51-38.25 24.3-107.98 71.37-10.22 7.02-19.48 10.43-27.77 10.26-9.14-.2-26.72-5.17-39.79-9.42-16.03-5.21-28.77-7.97-27.66-16.82.57-4.61 6.92-9.32 19.04-14.14z"/></svg>
        </a>
      )}
     
    </div>
    <div className="detail-container">
      <p>{member.detail}</p>
    </div>
  </div>
);

const Team = () => {
  return (
    <div id="team-container">
      <Helmet>
        <title>Team</title>
        <meta
          name="description"
          content="Meet the team behind Cherokee Acquisition."
        />
      </Helmet>
      <Header isHome={false} />
      <div id="team">
        <div className="intro">
          <h1 className="title">Team</h1>
          <div className="info-container">
            {members.map(member => memberCard(member))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
