import React from 'react';
import '../styles/ContactModal.css';
import Button from './Button';
import Modal from 'react-modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BarLoader } from 'react-spinners';
import ReCAPTCHA from 'react-google-recaptcha';

Modal.setAppElement('#root');


const INITIAL_STATE = {
  name: '',
  email: '',
  company: '',
  message: '',
  error: '',
  loading: false,
  recaptchaToken: '',
};

class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    this.setState({ ...INITIAL_STATE });
  }

  formSubmit = () => {
    if (!this.validateFields()) return;
    this.setState({ error: '', loading: true });
    axios
      .post('/api/contact-us', {
        name: this.state.name,
        email: this.state.email,
        company: this.state.company,
        message: this.state.message,
        recaptchaToken: this.state.recaptchaToken
      })
      .then(response => {
        setTimeout(() => {
          this.onRequestClose();
          this.setState({ ...INITIAL_STATE });
          toast(
            'Thank you for contacting Cherokee. The team will reply to your message shortly!',
            {
              className: 'cherokee-toast'
            }
          );
        }, 3000);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .finally(function() {
        // always executed
      });
  };

  validateFields = () => {
    if (!this.state.name) {
      this.setError('Name is required');
      return false;
    }
    if (!this.state.email) {
      this.setError('Email is required');
      return false;
    }
    if (!this.validateEmail(this.state.email)) {
      this.setError('Please enter a valid email address');
      return false;
    }
    if (!this.state.message) {
      this.setError('Message is required');
      return false;
    }
    return true;
  };

  validateEmail = email => {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  };

  setError = msg => {
    this.setState({ error: msg });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onRecaptchaChange = event => {
    console.log('recaptchaToken: ', event);
    this.setState({ recaptchaToken: event });
  };
  onRequestClose = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.onRequestClose();
  };

  render() {
    let customStyles = {
      content: {
        position: 'fixed',
        maxWidth: '500px',
        width: '80%',
        height: '500px',
        backgroundColor: '#ffffff',
        overflow: 'auto',
        outline: 'none',
        padding: '30px 20px',
        border: 'none',
        borderRadius: '0',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around'
      },
      overlay: {
        backgroundColor: 'rgba(70, 70, 70, 0.8)',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
      },
      modalPara: {
        textAlign: 'center',
        paddingBottom: '10px',
        paddingTop: '6px',
        fontSize: '14px',
        color: '#464646'
      }
    };
    const { classes } = this.props;
    
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
        className="header-modal"
      >
        <form id="contact-form">
          <h1>
            <span>C</span>ONTACT <span>U</span>S
          </h1>
          <p style={customStyles.modalPara}>Feel free to contact us at 212-259-4300, or send a message:</p>
          {this.state.error && (
            <p style={{ color: 'red' }}>{this.state.error}</p>
          )}
          <input
            type="text"
            id="custom-css-outlined-input"
            placeholder="Name"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
          />
          <input
            label="Email"
            placeholder="Email"
            type="email"
            id="custom-css-outlined-input"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
          />
          <input
            label="Company"
            id="custom-css-outlined-input"
            type="text"
            placeholder="Company"
            margin="normal"
            variant="outlined"
            name="company"
            value={this.state.company}
            onChange={this.onChange}
          />
          <textarea
            label="Message"
            type="text"            
            rows="6"
            name="message"
            placeholder="Message"
            value={this.state.message}
            onChange={this.onChange}
          />
          <BarLoader
            sizeUnit={'px'}
            width={240}
            height={4}
            color={'#000078'}
            loading={this.state.loading}
          />
            <ReCAPTCHA
              sitekey="6Ld3rl0qAAAAAJS3krtAA_VQopk7z-00UaSCLHG_"
              onChange={this.onRecaptchaChange}
              style={{ marginTop: '14px', marginBottom: '14px' }}
            />
          <Button title="Send" onButtonClick={this.formSubmit} />
        </form>
      </Modal>
    );
  }
}

export default ContactModal;
