import React from 'react';
import '../styles/Button.css';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }
  render() {
    const { title } = this.props;
    return (
      <div id="default-btn" onClick={this.onButtonClick}>
        {title}
      </div>
    );
  }

  onButtonClick() {
    const { onButtonClick } = this.props;
    if (onButtonClick) {
      onButtonClick();
    }
  }
}

export default Button;
