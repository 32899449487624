import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import ContactModal from './ContactModal';
import * as Scroll from 'react-scroll';

const animateScroll = Scroll.animateScroll;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.year = new Date().getFullYear()


    this.state = {
      menuActive: false,
      contactActive: false
    };
  }

  openContact = () => {
    animateScroll.scrollToTop();
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <div id="footer">
        <div className="info-container">
          <div className="brand-info">
            <Link to="/" className="link" onClick={animateScroll.scrollToTop}>
              <h4>Cherokee Acquisition</h4>
            </Link>

            <a
              className="address"
              href="https://goo.gl/maps/iXMt5k1c7xLJfNA67"
              target="_blank"
              rel="noopener noreferrer">

              <div>1384 Broadway, Suite 906</div>
              <div>New York, NY 10018</div>
            </a>

            <p><a href="tel:2122594300">(212) 259-4300</a></p>

            <p><a href="mailto:incomings@cherokeeacq.com">incomings@cherokeeacq.com</a></p>
          </div>

          <div className="link-container">
            <Link
              to="/claims"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Claims
            </Link>

            <Link
              to="/put-options"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Vendor Put Options
            </Link>

            <Link
              to="/receivables"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Receivables Finance
            </Link>

            <Link
              to="/team"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Team
            </Link>

            <Link
              to="/resources"
              className="link"
              onClick={animateScroll.scrollToTop}>
              Resources
            </Link>
          </div>
        </div>

        <div className="copy-right">
          <div className="linkedin">
              <a href="https://www.linkedin.com/company/cherokee-acquisition/" target='_blank'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="white" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
              </a>
          </div>
          <div className="telegram">
              <a href="https://t.me/+AS5IvZwp2S4xNWMx" target='_blank'>
                 <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path fill="#fff" d="M512 256C512 114.62 397.38 0 256 0S0 114.62 0 256s114.62 256 256 256 256-114.62 256-256zm-396.12-2.7c74.63-32.52 124.39-53.95 149.29-64.31 71.1-29.57 85.87-34.71 95.5-34.88 2.12-.03 6.85.49 9.92 2.98 2.59 2.1 3.3 4.94 3.64 6.93.34 2 .77 6.53.43 10.08-3.85 40.48-20.52 138.71-29 184.05-3.59 19.19-10.66 25.62-17.5 26.25-14.86 1.37-26.15-9.83-40.55-19.27-22.53-14.76-35.26-23.96-57.13-38.37-25.28-16.66-8.89-25.81 5.51-40.77 3.77-3.92 69.27-63.5 70.54-68.9.16-.68.31-3.2-1.19-4.53s-3.71-.87-5.3-.51c-2.26.51-38.25 24.3-107.98 71.37-10.22 7.02-19.48 10.43-27.77 10.26-9.14-.2-26.72-5.17-39.79-9.42-16.03-5.21-28.77-7.97-27.66-16.82.57-4.61 6.92-9.32 19.04-14.14z"/></svg>
              </a>
          </div>
          <p>Copyright © {this.year} Cherokee Trading, LLC.</p>
          <a href="https://ravennainteractive.com" target="_blank">
            Powered by Ravenna
          </a>
        </div>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />
          
      </div>
    );
  }
}

export default Footer;
