// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Avenir-Black';
  src: url('/public/assets/fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/public/assets/fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  font-family: 'Avenir';
  font-weight: 400;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./client/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,qEAAqE;EACrE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,qEAAqE;EACrE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;;EAGE,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;;EAEE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE;aACW;AACb","sourcesContent":["@font-face {\n  font-family: 'Avenir-Black';\n  src: url('/public/assets/fonts/Avenir-Medium.ttf') format('truetype');\n  font-weight: 600;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir';\n  src: url('/public/assets/fonts/Avenir-Medium.ttf') format('truetype');\n  font-weight: 400;\n  font-style: normal;\n}\n\n*,\n*:before,\n*:after {\n  margin: 0;\n  padding: 0;\n  font-family: 'Avenir';\n  font-weight: 400;\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n  -ms-box-sizing: border-box;\n}\n\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow-x: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
