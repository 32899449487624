import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Button from './Button';
import ContactModal from './ContactModal';

import '../styles/Receivables.css';
import '../styles/Reviews.css';

class Reviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactActive: false
    };
  }

  openContact = () => {
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <div id="venture-container">
        <Helmet>
          <title>Reviews</title>
          <meta
            name="description"
            content="Cherokee provides efficient access to capital for vendors. We are strategically positioned between vendor clients and providers of capital who can service invoices. We follow over 100 credits and are able to offer insights on multiple industries." />

        </Helmet>

        <Header isHome={false} />

        <div id="venture">
          <div className="intro">
            <h1 className="title">Reviews</h1>
          </div>

          <div className="reviews-wrapper">
            <iframe src="https://widget.tagembed.com/52858?view"></iframe>
          </div>

         
        </div>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />

      </div>
    );
  }
}

export default Reviews;
