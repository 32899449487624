// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  width: 100%;
  margin-bottom: 36px;
}

.carousel-focus-container,
.carousel-img-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-focus-container {
  width: 500px;
  margin: 0 15px;
}

.carousel-img-container {
  height: 80%;
  width: 300px;
}

.carousel-image,
.carousel-image-cover,
.carousel-focus-image {
  width: 100%;
}

.carousel-image-cover {
  background-color: rgba(244, 244, 244, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
}

.arrow-container {
  width: 96px;
  display: flex;
  justify-content: space-between;
}

.arrow-container img {
  opacity: 0.7;
  cursor: pointer;
}

.arrow-container img:hover {
  opacity: 1;
}

@media (max-width: 576px) {
  .carousel-focus-container {
    width: 100%;
  }
  .carousel-container {
    height: 240px;
    margin-bottom: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./client/styles/Carousel.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;;EAGE,WAAW;AACb;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;AACF","sourcesContent":[".carousel {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 40px;\n}\n\n.carousel-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  height: 300px;\n  width: 100%;\n  margin-bottom: 36px;\n}\n\n.carousel-focus-container,\n.carousel-img-container {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.carousel-focus-container {\n  width: 500px;\n  margin: 0 15px;\n}\n\n.carousel-img-container {\n  height: 80%;\n  width: 300px;\n}\n\n.carousel-image,\n.carousel-image-cover,\n.carousel-focus-image {\n  width: 100%;\n}\n\n.carousel-image-cover {\n  background-color: rgba(244, 244, 244, 0.4);\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.arrow-container {\n  width: 96px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.arrow-container img {\n  opacity: 0.7;\n  cursor: pointer;\n}\n\n.arrow-container img:hover {\n  opacity: 1;\n}\n\n@media (max-width: 576px) {\n  .carousel-focus-container {\n    width: 100%;\n  }\n  .carousel-container {\n    height: 240px;\n    margin-bottom: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
