import React from 'react';
import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';

import ContactModal from './ContactModal';
import Header from './Header';
import Button from './Button';
import Carousel from './Carousel';

import '../styles/Claims.css';

const animateScroll = Scroll.animateScroll;

const carouselItems = [
  './assets/images/claims_market_1.png',
  './assets/images/claims_market_2.png',
  './assets/images/claims_market_3.png'
];

class Claims extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactActive: false
    };
  }

  openContact = () => {
    animateScroll.scrollToTop();
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    return (
      <div id="claims-container">
        <Helmet>
          <title>Claims</title>
          <meta
            name="description"
            content="We have simplified the process of selling a bankruptcy claim. Prior to the global financial crisis of 2008, there was a greater disparity in terms for selling claims." />
        </Helmet>

        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact} />

        <Header isHome={false} />

        <div id="claims">
          <div className="intro">
            <h1 className="title">Claims</h1>
            <Button 
              title="Visit Claims Market"
              onButtonClick={() =>
                window.open('https://claims-market.com', '_blank')
              } />
            {/* <img className="claims-icon" src="./assets/icons/claims.png" /> */}
            <div className="detail-container">
              <div className="img-container first">
                <img src="./assets/images/energy_intro_1.png" />
              </div>
              
              <div className="description-container">
                <p>
                  Fundamental analysis and knowledge of bankruptcy law provide
                  us with an edge in offering highly competitive prices and
                  terms. We have in-house documentation experts. This allows us
                  to quickly respond to each seller’s concerns and deliver
                  optimal terms.
                </p>
              </div>
            </div>

            <div className="detail-container">
              <div className="description-container second">
                <p>
                  We put creditors first. We are experts in buying and trading
                  creditor claims in bankruptcy cases. Our goal is fast,
                  efficient trade processing and closing.
                </p>
              </div>

              <div className="img-container second">
            
                  <img src="./assets/images/JL-whiteboard.jpg" className="whiteboard-img" />

              </div>
            </div>

           
              
          </div>

          <div className="claims-market">
            <h1 className="title">Claims Market</h1>
            <Carousel items={carouselItems} />

            <p>
              The market for claims has two segments. One segment is the
              over-the-counter market where a seller and a buyer negotiate price
              and terms bilaterally. This method is most appropriate for very large,
              complex claims. The other segment is the hybrid web-based market
              where a seller’s claim is seen by multiple buyers through the
              Claims Market portal. This method is most appropriate for claims
              scheduled by a debtor.
            </p>

            <p>
              We have simplified the process of selling a bankruptcy claim.
              Prior to the global financial crisis of 2008, there was a greater
              disparity in terms for selling claims. Since then, there has been
              less negotiation of terms as market standards have coalesced
              around agreements that fairly balance the interests of
              claim sellers and buyers. We have distilled these market
              standards into our SAC (Simple Assignment of Claim).
            </p>

            <Button
              title="Visit Claims Market"
              onButtonClick={() =>
                window.open('https://claims-market.com', '_blank')
              } />

          </div>
        </div>
      </div>
    );
  }
}

export default Claims;
